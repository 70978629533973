import request from '@/utils/request'
const requestApi = {
  wms_supervised_warehouse: '/wms/supervised_warehouse/warehouse/',
  wms_supervised_inventory: 'wms/supervised_warehouse/inventory/',
  wms_supervised_warehouse_cargo_space_list: '/wms/supervised_warehouse/cargo_space/'
}

export function wms_supervised_warehouse_cargo_space_list (query) {
  return request({
    url: requestApi.wms_supervised_warehouse_cargo_space_list,
    method: 'get',
    params: query
  })
}
export function wms_common_warehouse_list (query) {
  return request({
    url: requestApi.wms_supervised_warehouse,
    method: 'get',
    params: query
  })
}
export function wms_supervised_inventory (query) {
  return request({
    url: requestApi.wms_supervised_inventory,
    method: 'get',
    params: query
  })
}
