<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="公共仓库">
              <a-select
                option-label-prop="tt"
                show-search
                allow-clear
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                v-model="queryParam.common_warehouse_id"
                placeholder="请输入仓库名称"
                :default-active-first-option="false"
                @search="handlecommon_warehouseSearch"
                @change="handlecommon_warehouseChange"
              >
                <a-select-option v-for="d in dataSource_common_warehouse" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="商品">
              <a-input v-model="queryParam.wms_supervised_warehouse_commodity" placeholder="请输入商品" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <!--          <a-col :md="6" :sm="24">
            <a-form-item label="公共仓库位">
              <a-select
                option-label-prop="tt"
                show-search
                allow-clear
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                v-model="queryParam.common_warehouse_cargo_space_id"
                placeholder="请输入库位"
                :default-active-first-option="false"
                @search="handlecommon_warehouse_cargo_spaceSearch"
                @change="handlecommon_warehouse_cargo_spaceChange"
              >
                <a-select-option v-for="d in dataSource_common_warehouse" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          -->
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>
  </div>
</template>

<script>
import moment from 'moment'
import { Dictionaries } from '@/common/AllConstant'
import {
  wms_common_warehouse_list,
  wms_supervised_inventory,
  wms_supervised_warehouse_cargo_space_list
} from '@/api/c_wms_common_warehouse'
import { STable } from '@/components'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      dataSource_common_warehouse: [],
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '货物',
          dataIndex: 'wms_supervised_warehouse_goods'
        },
        {
          title: '商品',
          align: 'center',
          dataIndex: 'wms_supervised_warehouse_commodity'
        },
        {
          title: '库存数量',
          align: 'center',
          dataIndex: 'count'
        },
        {
          title: '库存冻结数量',
          align: 'center',
          dataIndex: 'freeze_count'
        },
        {
          title: '仓库',
          align: 'center',
          dataIndex: 'wms_supervised_warehouse'
        },
        {
          title: '库位',
          align: 'center',
          dataIndex: 'wms_supervised_warehouse_cargo_space'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wms_supervised_inventory(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {},
  methods: {
    handlecommon_warehouseSearch (value) {
      wms_common_warehouse_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_common_warehouse = !value ? [] : result
      })
    },
    handlecommon_warehouseChange (value) {
      this.queryParam.common_warehouse_id = value
    },
    handlecommon_warehouse_cargo_spaceSearch (value) {
      wms_supervised_warehouse_cargo_space_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_warehouse_cargo_space = !value ? [] : result
      })
    },
    handlecommon_warehouse_cargo_spaceChange (value) {
      this.queryParam.common_warehouse_cargo_space_id = value
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
